<!-- 资讯详情 -->
<template>
  <div class="do-page-information-details">
    <base-nav-bar @click-left="$router.go(-1)" left-arrow :title="info.title">
    </base-nav-bar>
    <!-- <iframe width="100%" height="100%" src="https://m.isjike.com/h-nd-126.html">
      <p>您的浏览器不支持 iframe 标签。</p>
    </iframe> -->
    <base-bscroll
      @refScroll="refScroll"
      :safeareaNavbar="true"
      v-if="html ? true : false"
      :options="{ bounce: true, useTransition: false }"
    >
      <div class="do-page-informationdetails-content">
        <div class="title">{{ info.title }}</div>
        <div class="info">
          <span>时间：{{ info.release_time }}</span>
          <!-- <span class="split">|</span> -->
          <!-- <span>来源：赢商网</span> -->
        </div>
        <div v-html="html"></div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import { getConsultsdetails } from "@/axios/information";
const info = ref({});
const html = ref("");
const route = useRoute();
let scrollRef = null;
let timer = null;
function refScroll(scroll) {
  scrollRef = scroll;
  scroll.on("scrollEnd", () => {
    if (timer) clearTimeout(timer);
    nextTick(() => {
      if (scrollRef) scrollRef.refresh();
    });
    console.log("scrollEnd");
  });
}

async function getDatasDetails() {
  try {
    const { code, data } = await getConsultsdetails(route.params.id);
    if (code === 0 && data) {
      info.value = data;
      html.value = data.content;
      console.log(" data.content", data.content);
      nextTick(() => {
        if (scrollRef) scrollRef.refresh();
      });
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
}

onMounted(() => {
  getDatasDetails();
});

onMounted(() => {
  // document.documentElement.style.fontSize = "24px";
});
onBeforeUnmount(() => {
  // document.documentElement.style.fontSize = "50px";
});
</script>

<style lang="scss">
.do-page-information-details {
  height: 100%;
  .do-com-navbar {
    background: #0057ff;
    .van-nav-bar__arrow {
      color: #ffffff !important;
    }
    .van-nav-bar__title {
      font-size: 0.32rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .do-page-informationdetails-content {
    padding: 0.32rem;
    color: #666;

    .title {
      font-size: 0.32rem;
      font-weight: 500;
      color: #02040f;
    }
    .info {
      margin-top: 0.08rem;
      margin-bottom: 0.24rem;
      .split {
        margin: 0 0.16rem;
        color: rgba(2, 4, 15, 0.12);
      }
      span {
        font-size: 0.24rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.25);
      }
    }
  }
  .do-com-scroll-wrapper {
    // background: #f5f5f5;
    background: #ffffff;
    strong {
      font-weight: bold;
    }
    .jz_fix_ue_img {
      font-size: 0.65rem;
      background: #ffffff;
      h1 {
        font-size: 2em;
        font-weight: bold;
      }
    }
  }
}
</style>
